import React, { useEffect, useState } from 'react';
import { NextSeo } from 'next-seo';

import Header from '../../components/enfusion/Header';
import Footer from '../../components/enfusion/Footer';
import { useAppContext } from '../../util/context';
import { useRouter } from 'next/router';
import Sidebar from './sidebar';
import SocialShare from '@components/enfusion/SocialShare';

const NewAudioPodcast = ({ pageProps = {}, children }) => {
    const {
        nextSeoProps = {},
        menus = {},
        options = {},
        acf_newaudiopodcast_meta = {}
    } = pageProps;
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const context = useAppContext();
    context.toggleDarkMode(false);
    context.toggleHeaderTheme('bg-white');

    const [firstChild, ...restChildren] = React.Children.toArray(children);

    return (
        <>
            <NextSeo {...nextSeoProps} />
            <Header
                menus={menus}
                socialMediaLinks={options?.socialMediaLinks}
                {...pageProps}
            />
            <main>
                <div className="flex flex-col">
                    {firstChild && isClient ? (
                        <div className="flex-1 w-full">{firstChild}</div>
                    ) : null}
                    <div className="md:flex-1 md:flex md:flex-row-reverse container gap-x-12 md:mt-16 mt-0">
                        <div className="md:flex-initial md:w-1/4 md:ml-4 ml-0">
                            <Sidebar
                                logoImage={acf_newaudiopodcast_meta?.logoImage}
                                type={acf_newaudiopodcast_meta?.type}
                                focus={acf_newaudiopodcast_meta?.focus}
                                aumTier={acf_newaudiopodcast_meta?.aumTier}
                                podcast_exerpt={acf_newaudiopodcast_meta?.podcast_exerpt}
                            />
                        </div>
                        <div className="md:flex-1 md:w-3/4 ">
                            {restChildren}
                            {/* <button>
                                <a
                                    target={
                                        acf_newaudiopodcast_meta?.pdfDownload?.target
                                    }
                                    href={acf_newaudiopodcast_meta?.pdfDownload?.url}
                                    className="cursor-pointer btn btn--primary"
                                >
                                    {acf_newaudiopodcast_meta?.pdfDownload?.title}
                                </a>
                            </button> */}
                            <SocialShare />
                        </div>
                    </div>
                </div>
            </main>
            <Footer {...pageProps} />
        </>
    );
};

export default NewAudioPodcast;
